
















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IAuthUser } from '@/types/interfaces'

@Component
export default class SignUp extends Vue {
  private user: IAuthUser = { email: '', password: '' }
  @Prop(Boolean) public loading: Boolean

  @Emit('onSubmit')
  submit() {
    return this.user
  }
}
